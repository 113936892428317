import {
  api,
  createCrudService,
  responseToOptions,
  responseToOptionsInContext
} from '@cargill/shared'
import _ from 'lodash'

const service = createCrudService('/api/booking', api)

service.processValidationContext = (component, ctx, meta) => {
  responseToOptionsInContext(ctx)
  const fieldsById = _.keyBy(meta.fields, (field) => field.id)

  Object.keys(ctx.departureTerminalsByPort).forEach((x) => {
    ctx.departureTerminalsByPort[x] = responseToOptions(
      ctx.departureTerminalsByPort[x]
    )
  })

  ctx.unityOptionsMass = responseToOptions(ctx.unityOptionsMass)
  ctx.unityOptionsPrice = responseToOptions(ctx.unityOptionsPrice)

  fieldsById.carrier.options = ctx.carrierOptions
  fieldsById.originPort.options = ctx.portOptions
  fieldsById.departureTerminal.options = async ({ itemData }) => {
    const portId = itemData?.originPort?.value
    return ctx.departureTerminalsByPort[portId] ?? []
  }
  fieldsById.destinationPort.options = ctx.portOptions
  fieldsById.costByContainerUnit.options = ctx.unityOptionsPrice
}

export default service
