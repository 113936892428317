<template>
  <div v-if="metadata != null">
    <cargill-crud-meta-view
      :service="crudService"
      :metadata="metadata"
      translatePrefix="application.pages"
    />
  </div>
</template>

<script>
import { CargillCrudMetaView } from '@cargill/shared'
import service from '../api/bookingService'

export default {
  components: { CargillCrudMetaView },
  data() {
    return {
      metadata: null,
      crudService: service
    }
  },
  created() {
    const getMeta = async () => {
      const bookingMeta = await service.getMetaWithValidation()
      return bookingMeta
    }
    getMeta().then((meta) => {
      this.metadata = meta
    })
  }
}
</script>
